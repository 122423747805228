import { crashReported } from '@/state/crash/crashSlice';
import type { Dispatch } from '@reduxjs/toolkit';
import { connect, MapDispatchToProps } from 'react-redux';
import { Catch } from './Utils/Catch';
import { logger } from '@/helpers/logger';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import type { ErrorInfo, ReactNode } from 'react';

export interface AppCrashConnectOwnProps {
  children: ReactNode;
}

export interface AppCrashConnectDispatchProps {
  onCatch(error: Error, errorInfo: ErrorInfo): void;
}

export const mapDispatchToPropsAppCrash: MapDispatchToProps<
  AppCrashConnectDispatchProps,
  AppCrashConnectOwnProps
> = (dispatch: Dispatch) => ({
  onCatch(error: Error) {
    logger.logError('Caught react error in boundary {message_s}', error.message);
    mapStackTrace(error.stack!, stack => {
      logger.logError('React error stack trace {stack_s}', stack.join('\n'));
    });
    dispatch(crashReported({ message: error.message, stack: error.stack }));
  },
});

export const AppCrash = connect(null, mapDispatchToPropsAppCrash)(Catch);
