import type { HubConnection } from '@microsoft/signalr';
import type { Deal, MarketData, Order, Strategy } from '@/types/api/sotw';
import { PascalCaseObject, toJsCase } from '@/helpers/case';
import { logger } from '@/helpers/logger';
import type { AlgoOrder } from '@/types/api/algoOrders';
import { parseTimestamp } from '@/helpers/format';
import { Coordinate, toCoordinates } from '@/helpers/highcharts';
import { AlgoExecutionState, isValidAverage } from '@/state/api/mapAlgoExecutionState';
import { max, min } from '@/helpers/functions';
import type { PatchCollection, Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { isNotDefined } from '@sgme/fp';
import { getConfig } from '@/helpers/config';

export type SotwUpdater = (updateRecipe: Recipe<AlgoExecutionState>) => PatchCollection;
export type AlgoUpdater = (updateRecipe: Recipe<Record<string, AlgoOrder>>) => PatchCollection;

export function updateDeals(orderId: string, update: SotwUpdater) {
  return (deal: PascalCaseObject<Deal>) => {
    const updatedId = deal.Reference;
    if (updatedId.toLowerCase() !== orderId.toLowerCase()) {
      return;
    }
    logger.logInformation('Received deal for {orderId}: {@deal_o}', updatedId, deal);
    update(computeNewDeal(deal));
  };
}

export function updateStrategies(orderId: string, update: SotwUpdater) {
  return (strategy: PascalCaseObject<Strategy>) => {
    const updatedId = strategy.Reference;
    if (updatedId.toLowerCase() !== orderId.toLowerCase()) {
      return;
    }
    logger.logInformation('Received strategy for {orderId}: {@strategy_o}', updatedId, strategy);
    update(computeNewStrategy(strategy));
  };
}

export function updateOrders(orderId: string, update: SotwUpdater) {
  return (order: PascalCaseObject<Order>) => {
    const updatedId = order.Reference;
    if (updatedId.toLowerCase() !== orderId.toLowerCase()) {
      return;
    }
    logger.logInformation('Received order {orderId}: {order_s}', updatedId, JSON.stringify(order));
    update(draftSotw => {
      if (isNotDefined(draftSotw)) {
        return;
      }
      draftSotw.orders.push(toJsCase(order));
    });
  };
}

export function updateMarketData(orderId: string, update: SotwUpdater) {
  return (marketData: PascalCaseObject<MarketData>) => {
    const updatedId = marketData.Reference;
    if (updatedId.toLowerCase() !== orderId.toLowerCase()) {
      return;
    }
    logger.logDebug('Received marketData {orderId}: {@market_data_o}', updatedId, marketData);
    update(computeNewMarketData(marketData));
  };
}

function computeNewDeal(deal: PascalCaseObject<Deal>): Recipe<AlgoExecutionState> {
  return draftSotw => {
    const incomingDeal = toJsCase(deal);
    const point = toCoordinates(incomingDeal, 'grossPrice');
    if (incomingDeal.orderType === 'Aggressive') {
      draftSotw.deals.aggressive = [...draftSotw.deals.aggressive, point];
      draftSotw.deals.aggressiveDeals = [...draftSotw.deals.aggressiveDeals, incomingDeal];
    } else {
      draftSotw.deals.passive = [...draftSotw.deals.passive, point];
      draftSotw.deals.passiveDeals = [...draftSotw.deals.passiveDeals, incomingDeal];
    }
  };
}

function computeAverage(incomingStrategy: Strategy, currentAverage: Coordinate[]) {
  if (!isValidAverage(incomingStrategy.executedGrossPrice)) {
    return currentAverage;
  }
  return [...currentAverage, toCoordinates(incomingStrategy, 'executedGrossPrice')];
}

function getLatestStrategy(incomingStrategy: Strategy, latestStrategy: Strategy | undefined) {
  if (latestStrategy === undefined) {
    return incomingStrategy;
  }
  return parseTimestamp(incomingStrategy.timestamp) > parseTimestamp(latestStrategy.timestamp)
    ? incomingStrategy
    : latestStrategy;
}

function computeNewStrategy(strategy: PascalCaseObject<Strategy>): Recipe<AlgoExecutionState> {
  return draftSotw => {
    const incomingStrategy = toJsCase(strategy);
    if (incomingStrategy.status !== 'New') {
      draftSotw.strategies = {
        latestStrategy: getLatestStrategy(incomingStrategy, draftSotw.strategies.latestStrategy),
        average: computeAverage(incomingStrategy, draftSotw.strategies.average),
        transferPrice: [
          ...draftSotw.strategies.transferPrice,
          toCoordinates(incomingStrategy, 'transferPrice'),
        ],
        requestedPrice: [
          ...draftSotw.strategies.requestedPrice,
          toCoordinates(incomingStrategy, 'requestedPrice'),
        ],
      };
    } else {
      draftSotw.strategies.latestStrategy = getLatestStrategy(
        incomingStrategy,
        draftSotw.strategies.latestStrategy,
      );
    }
  };
}

function computeNewMarketData(
  marketData: PascalCaseObject<MarketData>,
): Recipe<AlgoExecutionState> {
  return draftSotw => {
    const incomingMarketData = toJsCase(marketData);

    const minBid = min(incomingMarketData.bid, draftSotw.marketData.minBid);
    const maxAsk = max(incomingMarketData.ask, draftSotw.marketData.maxAsk);

    draftSotw.marketData = {
      bid: [...draftSotw.marketData.bid, toCoordinates(incomingMarketData, 'bid')],
      ask: [...draftSotw.marketData.ask, toCoordinates(incomingMarketData, 'ask')],
      minBid,
      maxAsk,
    };
  };
}

let tickingTimeoutId: ReturnType<typeof setTimeout> | undefined;
function clearTickingTimeout() {
  if (tickingTimeoutId) {
    clearTimeout(tickingTimeoutId);
  }
}

export function listenToSotwStreaming(
  signalrConnection: HubConnection,
  orderId: string,
  updateCachedData: SotwUpdater,
): () => void {
  const durationMs = getConfig().no_recent_data_ms;

  /**
   * This function will set/clear a timeout to indicate when we haven't been getting any data recently
   */
  const tickingUpdateCachedData: SotwUpdater = update => {
    clearTickingTimeout();

    // reset existing flag
    updateCachedData(draftSotw => {
      draftSotw.noRecentData = false;
    });

    const patchCollection = updateCachedData(update);

    // set flag after not receiving any new data for timeout
    tickingTimeoutId = setTimeout(function warnNoRecentData() {
      if (navigator.onLine === false) {
        return;
      }
      updateCachedData(draftSotw => {
        draftSotw.noRecentData = true;
        logger.logWarning(
          'No recent TCA data received for order {orderId} for {durationMs}ms',
          orderId,
          durationMs,
        );
      });
    }, durationMs);

    return patchCollection;
  };

  const dealsUpdater = updateDeals(orderId, tickingUpdateCachedData);
  const strategiesUpdater = updateStrategies(orderId, tickingUpdateCachedData);
  const ordersUpdater = updateOrders(orderId, tickingUpdateCachedData);
  const marketDataUpdater = updateMarketData(orderId, tickingUpdateCachedData);

  signalrConnection.on('ReceiveDealMessage', dealsUpdater);
  signalrConnection.on('ReceiveStrategyMessage', strategiesUpdater);
  signalrConnection.on('ReceiveOrderMessage', ordersUpdater);
  signalrConnection.on('ReceiveMarketDataMessage', marketDataUpdater);

  return () => {
    clearTickingTimeout();
    signalrConnection.off('ReceiveDealMessage', dealsUpdater);
    signalrConnection.off('ReceiveStrategyMessage', strategiesUpdater);
    signalrConnection.off('ReceiveOrderMessage', ordersUpdater);
    signalrConnection.off('ReceiveMarketDataMessage', marketDataUpdater);
  };
}
