import { getConfig } from '@/helpers/config';

export function HelpCenter(): JSX.Element {
  return (
    <aside>
      <sgwt-help-center
        application-id={import.meta.env.VITE_ID as string}
        default-send-to={getConfig().mail_contact}
        mail-subject={`[SGME-FXTCA:${getConfig().app_env.toUpperCase()}] Help center message`}
        sg-connect-support="sg-connect-v2"
      />
    </aside>
  );
}
