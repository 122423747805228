import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { usePrecision } from '@/context/usePrecision';
import { LiveOrderHeaderItem } from './LiveOrderHeaderItem';

interface LiveOrderHeaderRateItemProps {
  title: string;
  price: number;
  perfBps: number;
  perfCurrency: number;
  currency: string;
}

const basePointsPrecision = 2;

export const LiveOrderHeaderRateItem = ({
  title,
  price,
  perfBps,
  perfCurrency,
  currency,
}: LiveOrderHeaderRateItemProps): JSX.Element => {
  const precision = usePrecision();
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="bottom"
      overlay={
        <Popover id="progress-popover">
          <div className="popover-header text-secondary text-left text-large" data-e2e={title}>
            <FormattedMessage id={`LiveOrderHeader.${title}.tooltip.title`} />
          </div>
          <div className="popover-body">
            <div className="text-secondary text-left">
              <div className="d-flex justify-content-between">
                <FormattedMessage id="LiveOrderHeader.rate.tooltip.rate" tagName="div" />
                <div className="font-weight-bold text-black">
                  <FormattedNumber value={price} maximumFractionDigits={precision} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <FormattedMessage id="LiveOrderHeader.rate.tooltip.prefBp" tagName="div" />
                <div
                  className={`font-weight-bold ${perfBps >= 0 ? 'text-success' : 'text-danger'}`}
                >
                  <FormattedNumber
                    value={perfBps}
                    maximumFractionDigits={basePointsPrecision}
                    signDisplay="always"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <FormattedMessage
                  id="LiveOrderHeader.rate.tooltip.prefCcy"
                  values={{ currency }}
                  tagName="div"
                />
                <div
                  className={`font-weight-bold ${
                    perfCurrency >= 0 ? 'text-success' : 'text-danger'
                  }`}
                >
                  <FormattedNumber
                    value={perfCurrency}
                    maximumFractionDigits={0}
                    signDisplay="always"
                  />
                </div>
              </div>
            </div>
          </div>
        </Popover>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <LiveOrderHeaderItem
          title={title}
          ref={ref}
          titleSupplement={<i className="icon">info_outline</i>}
          valueSupplement={
            <Badge
              data-e2e="badge"
              variant={perfBps > 0 ? 'discreet-success' : 'discreet-danger'}
              className="badge-lg"
            >
              <FormattedNumber
                value={perfBps}
                signDisplay="always"
                maximumFractionDigits={basePointsPrecision}
              />
            </Badge>
          }
          {...triggerHandler}
        >
          <div data-e2e="value">
            <FormattedNumber value={price} maximumFractionDigits={precision} />
          </div>
        </LiveOrderHeaderItem>
      )}
    </OverlayTrigger>
  );
};
