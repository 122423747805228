import { PropsWithChildren, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderIdContext } from '@/context';
import { getCurrentSpeedPosition } from '@/domain/strategy';
import { WhenFeatureEnabled } from '@/components/Utils/WhenFeatureEnabled';
import { useSotwData } from '@/state/api/api.hooks';

export function LiveOrderSpeed(): JSX.Element | null {
  const orderId = useContext(OrderIdContext);
  const strategy = useSotwData(data => data.strategies.latestStrategy);
  if (strategy === undefined) {
    return null;
  }
  const { speed, speedAverage, speedNormal, speedSlow, speedFast, requestedCcy } = strategy;

  const speedPosition = getCurrentSpeedPosition(strategy);
  return (
    <WhenFeatureEnabled name="speed">
      <div className="px-2">
        <div className={speedPosition < 25 ? 'text-right' : undefined}>
          <div className="text-secondary">
            <FormattedMessage id="LiveOrderSpeed.AverageSpeed.Label" />
          </div>
          <div className="display-4 text-figures mb-4 mt-1">
            <FormattedMessage
              id="LiveOrderSpeed.Speed.Content"
              values={{ speed: speedAverage, requestedCcy }}
            />
          </div>
        </div>
        <div className="speed">
          <div className="d-none">{orderId}</div>
          <div
            className="border-left border-right border-white h-100 position-relative bg-black"
            style={{ left: `${speedPosition}%`, width: 5, marginLeft: -3 }}
            id="speed"
          >
            <StaticTooltip horizontalPosition={speedPosition}>
              <div className="mb-1">
                <FormattedMessage id="LiveOrderSpeed.CurrentSpeed.Label" />
              </div>
              <div className="font-weight-bold">
                <FormattedMessage
                  id="LiveOrderSpeed.Speed.Content"
                  values={{ speed, requestedCcy }}
                />
              </div>
            </StaticTooltip>
          </div>
        </div>
        <div className="position-relative mt-2">
          <div className="speed-label very-slow">
            <FormattedMessage id="LiveOrderSpeed.Speed.VerySlow" />
            <SpeedTooltip speed={speedSlow / 2} ccy={requestedCcy} />
          </div>
          <div className="speed-label slow">
            <FormattedMessage id="LiveOrderSpeed.Speed.Slow" />
            <SpeedTooltip speed={speedSlow} ccy={requestedCcy} />
          </div>
          <div className="speed-label normal">
            <FormattedMessage id="LiveOrderSpeed.Speed.Normal" />
            <SpeedTooltip speed={speedNormal} ccy={requestedCcy} />
          </div>
          <div className="speed-label fast">
            <FormattedMessage id="LiveOrderSpeed.Speed.Fast" />
            <SpeedTooltip speed={speedFast} ccy={requestedCcy} />
          </div>
          <div className="speed-label very-fast">
            <SpeedTooltip speed={speedFast * 2} ccy={requestedCcy} />
            <FormattedMessage id="LiveOrderSpeed.Speed.VeryFast" />
          </div>
        </div>
      </div>
    </WhenFeatureEnabled>
  );
}

interface StaticTooltipProps {
  horizontalPosition: number;
}

function StaticTooltip({ horizontalPosition, children }: PropsWithChildren<StaticTooltipProps>) {
  return (
    <div className="position-absolute">
      <div
        className="position-relative show popover bs-popover-top"
        role="tooltip"
        style={{ top: -70, left: `-${horizontalPosition * 0.86 + 7}%` }} // It's magic ✨
      >
        <div className="card shadow text-primary p-2">{children}</div>
      </div>
      <div
        className="position-relative show popover bs-popover-top border-0"
        style={{ width: 0, top: -79, left: -7 }} // It's magic ✨
      >
        <div className="arrow"></div>
      </div>
    </div>
  );
}

function SpeedTooltip({ speed, ccy }: { speed: number; ccy: string }) {
  return (
    <div className="mx-2 shadow position-absolute badge badge-outline-secondary badge-pill">
      <FormattedMessage id="LiveOrderSpeed.Speed.Content" values={{ speed, requestedCcy: ccy }} />
    </div>
  );
}
