import { getAuthorizationToken } from './auth';
import { getConfig } from './config';
import { HubConnection, HubConnectionBuilder, LogLevel, RetryContext } from '@microsoft/signalr';
import { logger } from '@/helpers/logger';
import type { AppDispatch } from '@/state/store';
import { webApi } from '@/state/api/web.api';
import { streamingStateChanged } from '@/state/ui/uiSlice';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';

const {
  signalR: { hub_url, reconnectDelay },
} = getConfig();

function createSignalrConnection(): HubConnection {
  return new HubConnectionBuilder()
    .withUrl(hub_url, {
      accessTokenFactory: () => getAuthorizationToken() ?? '',
    })
    .withHubProtocol(new MessagePackHubProtocol())
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
        const {
          previousRetryCount,
          elapsedMilliseconds,
          retryReason: { stack: retryReasonStack },
        } = retryContext;
        logger.logWarning(
          'Attempt to auto reconnect. Retry count: {previousRetryCount_n}. Time spend reconnecting in ms: {elapsedMilliseconds_n}. Reason: {retryReasonStack_s}',
          previousRetryCount,
          elapsedMilliseconds,
          JSON.stringify(retryReasonStack),
        );

        if (retryContext.elapsedMilliseconds < 60 * 1000) {
          return reconnectDelay;
        } else {
          return reconnectDelay * 10;
        }
      },
    })
    .build();
}

export type MockedHubConnection = HubConnection & {
  mockMessage(methodName: string, data: any): void;
};

function createMockedSignalrConnection(): MockedHubConnection {
  const listeners = new Map();
  return {
    start() {
      return Promise.resolve();
    },
    stop() {
      return Promise.resolve();
    },
    onreconnected() {},
    onclose() {},
    on(methodName: string, listener: (...args: any[]) => void) {
      listeners.set(methodName, listener);
    },
    off(methodName: string) {
      listeners.delete(methodName);
    },
    mockMessage(methodName: string, data: any) {
      listeners.get(methodName)?.(data);
    },
  } as unknown as MockedHubConnection;
}

export const signalrConnection: HubConnection =
  import.meta.env.VITE_USE_MOCK === 'true'
    ? createMockedSignalrConnection()
    : createSignalrConnection();

export function bootstrapSignalRListeners(signalrConnection: HubConnection, dispatch: AppDispatch) {
  let retryCount = 0;
  const retryDelayMs = 2_500;

  const startConnection = () =>
    signalrConnection.start().then(
      () => {
        const connectionId = signalrConnection.connectionId!;
        logger.logInformation('Established signalR connection for {connectionId}', connectionId);
        dispatch(webApi.endpoints.postNotificationRegister.initiate(connectionId));
        dispatch(streamingStateChanged({ state: 'connected', connectionId }));
        retryCount = 0;
      },
      (reason: any) => {
        logger.logError(
          // There is already a field ConnectionId set by the backend (use signalrConnectionId instead of connectionId field here)
          'Fail to establish signalR connection for {signalrConnectionId} because {signalRConnectionFailureReason} attempt count {retryCount}',
          signalrConnection.connectionId,
          reason,
          retryCount,
        );
        setTimeout(startConnection, retryDelayMs);
      },
    );
  window.addEventListener('offline', async function () {
    await signalrConnection.stop();
  });

  window.addEventListener('online', function () {
    startConnection();
  });

  startConnection();

  signalrConnection.onreconnected((connectionId = signalrConnection.connectionId!) => {
    logger.logWarning('Re-established signalR connection for {connectionId}', connectionId);
    dispatch(webApi.endpoints.invalidateCache.initiate());
    dispatch(webApi.endpoints.postNotificationRegister.initiate(connectionId));
    dispatch(streamingStateChanged({ state: 'connected', connectionId }));
  });
  signalrConnection.onclose(() => {
    logger.logError('Lost signalR connection for {connectionId}', signalrConnection.connectionId);
    dispatch(webApi.endpoints.invalidateCache.initiate());
    dispatch(streamingStateChanged({ state: 'disconnected' }));
    // Try to reconnect right away
    startConnection();
  });
  signalrConnection.on('ReceiveDuplicatedConnection', () => {
    logger.logWarning(
      'Duplicate signalR connection for {connectionId}',
      signalrConnection.connectionId,
    );
    dispatch(streamingStateChanged({ state: 'duplicatedSession' }));
  });
}
