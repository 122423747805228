import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PageTracker } from './components/PageTracker';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { Header } from './components/Header';
import { PageNotFound } from '../pages/PageNotFound/PageNotFound';
import { HelpCenter } from './components/HelpCenter';
import { MiniFooter } from './components/MiniFooter';
import { useAppSelector } from '@/state/hooks';
import type { AppState } from '@/state/store';

export const PageLayout = (): JSX.Element => {
  const blotterCollapsed = useAppSelector((state: AppState) => state.ui.blotterCollapsed);
  return (
    <MainRouter>
      <div id="page" className={blotterCollapsed ? 'collapsed' : ''}>
        <Header />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/:orderId" component={Dashboard} />
          <Route component={PageNotFound} />
        </Switch>
        <HelpCenter />
        <MiniFooter />
      </div>
    </MainRouter>
  );
};

function MainRouter({ children }: { children: JSX.Element }): JSX.Element {
  return (
    <BrowserRouter>
      <PageTracker>{children}</PageTracker>
    </BrowserRouter>
  );
}
