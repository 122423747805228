import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSendHelpRequestMutation } from '@/state/api/web.api';
import { sgwtConnect } from '@/helpers/sgwtConnect';
import { cleanUpSensitiveInformation } from '@/helpers/cleanUpSensitiveInformation';
import { getStoreLastActions } from '@/state/store';

interface HelpButtonProps {
  details: string;
  stackTrace: string | undefined;
}

export function HelpButton({ details = '', stackTrace = '' }: HelpButtonProps): JSX.Element {
  const [startHelpRequest, response] = useSendHelpRequestMutation();
  const sendHelp = useCallback(() => {
    startHelpRequest({
      application: 'SGME-FX-TCA',
      config: 'appcrashed',
      details,
      lastActions: JSON.stringify(getStoreLastActions(), cleanUpSensitiveInformation),
      // likely to be too big
      // state: JSON.stringify(store.getState()),
      // TODO: Not yet handled by mailer
      stackTrace,
      sendConfirmation: true,
      login: sgwtConnect.getIdTokenClaims()!.sub,
    });
  }, [startHelpRequest, details, stackTrace]);

  if (response.isError) {
    return (
      <>
        <span className="text-danger pb-1">
          <FormattedMessage
            id="HelpButton.fail"
            values={{
              mailLink: <a href="mailto:sgmarketsfx@sgcib.com">sgmarketsfx@sgcib.com</a>,
            }}
          />
        </span>
        <button type="button" className="btn btn-icon-text btn-danger" onClick={sendHelp}>
          <i className="icon">close</i>
          <FormattedMessage id="HelpButton.send" />
        </button>
      </>
    );
  }
  if (response.isLoading) {
    return (
      <button type="button" className="btn btn-icon-text" disabled>
        <FormattedMessage id="HelpButton.sending" />
      </button>
    );
  }
  if (response.isSuccess) {
    return (
      <span className="text-success mx-2">
        <i className="icon mr-1">check</i>
        <FormattedMessage id="HelpButton.success" />
      </span>
    );
  }
  return (
    <button
      type="button"
      className="btn btn-primary"
      onClick={sendHelp}
      disabled={details.length === 0}
    >
      <FormattedMessage id="HelpButton.send" />
    </button>
  );
}
