import { FormattedNumber, FormattedMessage } from 'react-intl';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { LiveOrderHeaderItem } from './LiveOrderHeaderItem';
import { useMediaQuery } from '@/helpers/hooks/useMediaQuery';

interface LiveOrderHeaderItemAmountProps {
  title: string;
  currency: string;
  value: number;
  bonus?: number;
  usePercent?: boolean;
}

export const LiveOrderHeaderAmountItem = ({
  title,
  value,
  currency,
}: LiveOrderHeaderItemAmountProps): JSX.Element => {
  const isSmallView = useMediaQuery([['(min-width: 1200px)', false]], true);

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="bottom"
      overlay={
        <Popover id="progress-popover">
          <div className="popover-header text-secondary text-left text-large" data-e2e={title}>
            <FormattedMessage id={`LiveOrderHeader.${title}.tooltip.title`} />
          </div>
          <div className="popover-body">
            <div className="text-left font-weight-bold">
              <FormattedNumber value={value} maximumFractionDigits={0} /> {currency}
            </div>
          </div>
        </Popover>
      }
    >
      {({ ref, ...triggerHandlers }) => (
        <LiveOrderHeaderItem
          title={title}
          titleSupplement={currency}
          hasRightTitleSupplement={isSmallView}
          ref={ref}
          {...triggerHandlers}
        >
          <FormattedNumber value={value / 1_000_000} maximumFractionDigits={1} />
          &nbsp;
          <FormattedMessage id="LiveOrderHeader.shortMillion" />
        </LiveOrderHeaderItem>
      )}
    </OverlayTrigger>
  );
};
