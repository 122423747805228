import React, { useCallback, useState, useLayoutEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import './Blotter.scss';
import { FormattedMessage } from 'react-intl';
import { BlotterFilter, blotterFilters } from '@/domain/order';
import { OrderList } from '@/components/pages/Dashboard/Blotter/OrderList';
import { useAppSelector } from '@/state/hooks';
import { useDispatch } from 'react-redux';
import { blotterToggled } from '@/state/ui/uiSlice';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

interface FilterButtonGroupProps {
  current: BlotterFilter;
  onChange: (filter: BlotterFilter) => void;
}

const FilterButtonGroup = ({ current, onChange }: FilterButtonGroupProps): JSX.Element => {
  return (
    <ButtonGroup className="btn-group-lg col-12 px-0 mt-3">
      {blotterFilters.map(filter => (
        <Button
          variant="toggle-primary"
          active={current === filter}
          onClick={() => onChange(filter)}
          className="col-md-4 text-truncate"
          key={filter}
        >
          <FormattedMessage id={`Blotter.${filter}`} />
        </Button>
      ))}
    </ButtonGroup>
  );
};

export const Blotter = (): JSX.Element => {
  // const resizedRef = useRef<HTMLDivElement>(null);
  const isCollapsed = useAppSelector(state => state.ui.blotterCollapsed);
  const [currentFilter, setCurrentFilter] = useState<BlotterFilter>('today');
  const uncollapseBlotter = () => {
    if (isCollapsed) {
      toggleCollapsed();
    }
  };

  const [offsetPosition, setOffsetPosition] = useState(0);
  const [width, setWidth] = useState(500);
  const [isResizing, setIsResizing] = useState(false);

  const mouseUpHandler = useCallback(
    (event: MouseEvent) => {
      if (isResizing) {
        event.stopPropagation();
        event.preventDefault();
        setIsResizing(false);
      }
    },
    [isResizing, width],
  );
  const mouseMoveHandler = useCallback(
    (event: MouseEvent) => {
      if (isResizing) {
        event.stopPropagation();
        event.preventDefault();
        if (event.buttons !== 1) {
          mouseUpHandler(event);
          return;
        }
        const delta = offsetPosition - event.clientX;
        const maxWidth = window.innerWidth - 200;
        setOffsetPosition(event.clientX);
        setWidth(Math.max(Math.min(width - delta, maxWidth), 200));
      }
    },
    [isResizing, width],
  );
  const mouseDownHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isEventOnMatchingTarget(event, '[data-no-resize]')) {
        return;
      }
      event.stopPropagation();
      event.preventDefault();
      setOffsetPosition(event.clientX);
      setIsResizing(true);
    },
    [setIsResizing, setOffsetPosition, isResizing],
  );

  useLayoutEffect(() => {
    window.addEventListener('mousemove', mouseMoveHandler, true);
    window.addEventListener('mouseup', mouseUpHandler, true);
    return () => {
      window.removeEventListener('mousemove', mouseMoveHandler, true);
      window.removeEventListener('mouseup', mouseUpHandler, true);
    };
  }, [mouseMoveHandler, mouseUpHandler, isResizing]);
  const { trackEvent } = useMatomo();
  const dispatch = useDispatch();
  const toggleCollapsed = useCallback(() => {
    trackEvent({ category: 'Blotter', action: isCollapsed ? 'Expand' : 'Reduce' });
    dispatch(blotterToggled());
  }, [dispatch, isCollapsed, trackEvent]);

  const changeFilter = (filter: BlotterFilter) => {
    trackEvent({ category: 'Blotter', action: filter });
    setCurrentFilter(filter);
  };
  function isEventOnMatchingTarget(event: React.SyntheticEvent<HTMLElement>, selector: string) {
    let target = event.target as HTMLElement | null;
    while (target !== null && target !== event.currentTarget) {
      if (target.matches(selector)) {
        return true;
      }
      target = target.parentElement;
    }
    return false;
  }

  return (
    <aside
      style={{ width: !isCollapsed ? `${width}px` : '' }}
      id="blotter"
      onClick={uncollapseBlotter}
      className={`${isCollapsed ? 'blotter ' : ''}border-right`}
    >
      <div id="resize" onMouseDown={mouseDownHandler}></div>
      <nav id="button" className="justify-content-center mt-2">
        <button
          className="btn btn-lg btn-flat-secondary"
          onClick={() => {
            if (!isCollapsed) toggleCollapsed();
          }}
        >
          <em className="icon">{isCollapsed ? 'last_page' : 'first_page'}</em>
        </button>
      </nav>

      <section id="content" className="shadow">
        <header className="d-flex flex-column p-3 px-lg-4 border-bottom">
          <div className="d-flex flex-between">
            <h2 className="mb-0">
              <FormattedMessage id="Blotter.title" />
            </h2>
            <nav>
              <button className="btn btn-lg btn-flat-secondary" onClick={toggleCollapsed}>
                <em className="icon">{isCollapsed ? 'last_page' : 'first_page'}</em>
              </button>
            </nav>
          </div>
          <FilterButtonGroup current={currentFilter} onChange={changeFilter} />
        </header>

        <OrderList filter={currentFilter} toggleCollapsed={toggleCollapsed} />
      </section>
    </aside>
  );
};
