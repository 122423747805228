import { useEffect } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useLocation } from 'react-router-dom';
import { useGetUserInfoQuery } from '@/state/api/web.api';
import { addUserConsentSettingsCallback, getUserConsentOnMatomo } from '@/helpers/cookieConsent';
import { useIntl } from 'react-intl';
import { selectTheme } from '@/state/ui/uiSlice';
import { useSelector } from 'react-redux';

export function PageTracker({ children }: { children: JSX.Element }): JSX.Element {
  const { trackPageView, pushInstruction } = useMatomo();
  const location = useLocation();
  const userInfo = useGetUserInfoQuery();
  const { locale } = useIntl();
  const currentTheme = useSelector(selectTheme);

  useEffect(() => {
    pushInstruction('setUserId', userInfo.data?.email);
    pushInstruction('setCustomVariable', 1, 'locale', locale, 'visit');
    pushInstruction('setCustomVariable', 2, 'theme', currentTheme, 'visit');
    trackPageView({});
  }, [pushInstruction, locale, userInfo.data?.email, trackPageView, currentTheme]);

  // Every time the location changes, we track the page view on Matomo.
  useEffect(() => {
    function updateConsent() {
      getUserConsentOnMatomo().then(consent => {
        // Documentation: https://developer.sgmarkets.com/docs/web/widgets/structural-widgets/advanced-usage/cookies-consent.html
        if (consent) {
          pushInstruction('rememberCookieConsentGiven', 4320);
          pushInstruction('setCustomDimension', 10, true);
        } else {
          pushInstruction('forgetCookieConsentGiven');
          pushInstruction('setCustomDimension', 10, false);
        }
      });
    }

    pushInstruction('requireCookieConsent');
    updateConsent();
    trackPageView({});
    addUserConsentSettingsCallback(updateConsent);

    // Only care about change location
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{children}</>;
}
