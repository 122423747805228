import { query } from './query';
import { logger } from '@/helpers/logger';

export const themeKey = 'Theme';

export type Theme = 'DARK' | 'LIGHT';

const sgbsCdnUrl = `https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/${window.sgbsVersion}/`;

// FIXME: use the same urls as index.html
const variableSgbsUrls = (theme: string | null) => {
  const variation = theme === 'DARK' ? 'dark' : 'standard';
  return {
    sgbs: `${sgbsCdnUrl}core/sg-bootstrap-${variation}.min.css`,
    aggrid: `${sgbsCdnUrl}ag-grid-theme/28-${variation}.min.css`,
  };
};

const updateThemeUrls = (theme: 'DARK' | 'LIGHT') => {
  Object.entries(variableSgbsUrls(theme)).forEach(([id, url]) => {
    const linkElement = document.querySelector<HTMLLinkElement>(`link[id="${id}"]`);
    const newLinkElement = document.createElement('link');
    newLinkElement.rel = 'stylesheet';
    newLinkElement.id = id;
    newLinkElement.href = url;
    if (linkElement !== null) {
      newLinkElement.onload = () => {
        document.head.removeChild(linkElement);
      };
    }
    document.head.appendChild(newLinkElement);
  });
};

export function setTheme(theme: Theme) {
  updateThemeUrls(theme);
  logger.logInformation('User switched to theme {theme}', theme);
  // getMatomo().track("Workspace", "Switch theme", theme);
  if (localStorage) {
    try {
      localStorage.setItem(themeKey, theme);
    } catch {
      console.error('Unable to store theme, localStorage might be full');
    }
  }
}

export function getTheme(): Theme {
  const savedTheme = typeof localStorage !== 'undefined' ? localStorage.getItem(themeKey) : 'LIGHT';
  const useDark =
    (savedTheme && savedTheme.toUpperCase() === 'DARK') ||
    (query.has('theme') && query.get('theme')?.toString().toUpperCase() === 'DARK');

  return useDark ? 'DARK' : 'LIGHT';
}
