import { selectCrashError } from '@/state/crash/crashSlice';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import { HelpButton } from './HelpButton';
import { UncontrolledCollapsable } from '@/components/Utils/Collapsable';
import './CrashModal.scss';

const reload = () => window.location.reload();

export function CrashModal() {
  const { formatMessage } = useIntl();
  const [stackTrace, setStackTrace] = useState<string>();
  const [show, setShow] = useState(true);
  const error = useSelector(selectCrashError);
  const [userDetails, setUserDetails] = useState('');
  const setUserDetailsCallback = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    return setUserDetails(event.target.value);
  }, []);
  useEffect(() => {
    if (error === undefined) {
      return;
    }
    try {
      mapStackTrace(error.stack!, stack => {
        const stackTrace = stack.reduce((acc, curr) => `${acc}\n${curr}`, error.message);
        setStackTrace(stackTrace);
      });
    } catch (e) {
      console.error('Unable to translate stack trace ', e);
    }
  }, [error]);

  const close = useCallback(() => {
    if (import.meta.env.DEV) {
      setShow(false);
    }
  }, []);

  if (error === undefined) {
    return null;
  }
  return (
    <Modal
      show={show}
      keyboard={import.meta.env.DEV}
      onEscapeKeyDown={close}
      contentClassName="border border-danger"
      dialogClassName="crash-modal"
    >
      <Modal.Header>
        <Modal.Title className="text-danger">
          <FormattedMessage id="CrashModal.title" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage tagName="p" id="CrashModal.body" />
        <textarea
          className="form-control w-100"
          rows={4}
          placeholder={formatMessage({
            id: 'CrashModal.placeholder',
          })}
          onChange={setUserDetailsCallback}
          value={userDetails}
        />

        <UncontrolledCollapsable title={formatMessage({ id: 'CrashModal.collapsable' })}>
          <pre className="border bg-lvl2 text-primary mt-3 p-3 text-left overflow-auto technical-details">
            <code>
              {stackTrace === undefined
                ? error.message?.startsWith('{') // Potentially json object
                  ? JSON.stringify(JSON.parse(error.message), null, 4)
                  : error.message
                : `${error.message.substring(0, error.message.indexOf('\n'))}\n` + stackTrace}
            </code>
          </pre>
        </UncontrolledCollapsable>
      </Modal.Body>
      <Modal.Footer>
        <HelpButton details={userDetails} stackTrace={stackTrace} />
        <button type="button" className="btn btn-primary" onClick={reload}>
          <FormattedMessage id="CrashModal.reload" />
        </button>
      </Modal.Footer>
    </Modal>
  );
}
