import type { ISGWTConnectConfiguration } from '@sgwt/connect-core';

export interface AppConfig {
  env: string;
  app_env: string;
  mail_contact: string;
  no_recent_data_ms: number;
  api_url: string;
  sgwt_env: 'homologation' | 'production';
  sgconnect: ISGWTConnectConfiguration;
  unauthorized_url: string;
  fx_url: string;
  matomo: {
    url: string;
    site_id: string;
  };
  signalR: {
    hub_url: string;
    reconnectDelay: number;
  };
  settings?: {
    enableFakeAuthentication?: boolean;
  };
}

export function getConfig(): AppConfig {
  return window.sgmeConfiguration;
}

export const agGridLicenseKey =
  'CompanyName=SOCIETE GENERALE,LicensedGroup=SG Markets FX,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=2,AssetReference=AG-025632,ExpiryDate=2_April_2023_[v2]_MTY4MDM5MDAwMDAwMA==0ddb8fc70cf4cf2ae9d823aa6d8fad00';
