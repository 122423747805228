import type { PropsWithChildren } from 'react';
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { IntlProvider } from '@/context/IntlContext';
import { PageLayout } from './layout/PageLayout';
import { getConfig } from '@/helpers/config';
import { Provider } from 'react-redux';
import type { AppStore } from '@/state/store';
import { AppCrash } from './AppCrash';
import { CrashModal } from './Modals/CrashModal/CrashModal';
import { useGetUserInfoQuery } from '@/state/api/web.api';
import { Loading } from './Utils/Loading';

import './App.scss';
import { DuplicatedSessionModal } from './Modals/DuplicatedSessionModal/DuplicatedSessionModal';

export function App({ store }: { store: AppStore }): JSX.Element {
  const { matomo: matomoConfig } = getConfig();
  const matomo = createInstance({
    urlBase: matomoConfig.url,
    siteId: parseInt(matomoConfig.site_id, 10),
    trackerUrl: `${matomoConfig.url}/piwik.php`,
    srcUrl: `${matomoConfig.url}/piwik.js`,
  });

  return (
    <IntlProvider>
      <MatomoProvider value={matomo}>
        <Provider store={store}>
          <AppCrash>
            <CheckAuthorizations>
              <PageLayout />
            </CheckAuthorizations>
          </AppCrash>
          <CrashModal />
          <DuplicatedSessionModal />
        </Provider>
      </MatomoProvider>
    </IntlProvider>
  );
}

function CheckAuthorizations({ children }: PropsWithChildren<{}>): JSX.Element {
  const userInfo = useGetUserInfoQuery();

  if (userInfo.isLoading) {
    return <Loading />;
  }
  if (userInfo.isSuccess && Boolean(userInfo.data?.hasAccess)) {
    return <>{children}</>;
  }
  return <RedirectUnauthorized />;
}

function RedirectUnauthorized(): null {
  const { unauthorized_url } = getConfig();

  window.location.assign(unauthorized_url);
  return null;
}
